import Image from 'next/legacy/image';
import { t } from '../../generated/i18n';
import { crashPng } from '../../generated/images';
import { Container } from '../layout/container';
import Layout from '../layout/layout';
import { Button } from './button';

const NotFound = ({ preview }) => {
  return (
    <Layout preview={preview} robots='noindex'>
      <Container className='text-center'>
        <h1 className='my-10'>{t('pageNotFound')}</h1>
        <div>
          <Image {...crashPng} alt='' />
        </div>
        <Button className='mt-4' href='/'>
          {t('backToHome')}
        </Button>
      </Container>
    </Layout>
  );
};

export default NotFound;
